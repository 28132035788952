import Handler from '../../../interfaces/Handler';
import { LoggedInHandleParamsType } from '../types';

export default abstract class AbstractLoggedInHandler implements Handler {
  private nextHandler: AbstractLoggedInHandler;

  public setNext(handler: AbstractLoggedInHandler): AbstractLoggedInHandler {
    this.nextHandler = handler;
    return handler;
  }

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    if (this.nextHandler) {
      return this.nextHandler.handle(params);
    }

    return null;
  }
}
