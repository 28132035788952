import { InterfacesType } from '@jarvis/shell-commons/dist/interface';
import {
  shouldDisplayLoginMFeSpecification,
  shouldDisplayNativeAuthSpecification
} from '../specifications/login';
import { systemImportWithLocale } from '../../utils/systemImportWithLocale';
import { redirectUserToIdentityProvider } from './redirectUserToIdentityProvider';
import { ManifestType } from '../../types/manifest';
import doNativeAuthAndRedirectToLoggedin from './doNativeAuthAndRedirectToLoggedin';

export async function loginUseCase(
  interfaces: InterfacesType,
  manifest: ManifestType
): Promise<any> {
  const shouldDisplayNativeAuth = await shouldDisplayNativeAuthSpecification();
  if (shouldDisplayNativeAuth) {
    await doNativeAuthAndRedirectToLoggedin(interfaces);
  }

  if (shouldDisplayLoginMFeSpecification(manifest)) {
    const { login } = manifest?.services || {};
    const loginAssetReference =
      login?.assetReference || '@jarvis/react-shell-login';

    return (await systemImportWithLocale(loginAssetReference)) as any;
  }

  await redirectUserToIdentityProvider(interfaces);
}
