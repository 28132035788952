import AbstractLoggedInHandler from './AbstractLoggedInHandler';
import { LoggedInHandleParamsType } from '../types';
export default class PortalCustomUserOnboardingHandler extends AbstractLoggedInHandler {
  private EXCLUDED_KEYS_FROM_STATE_LIST = ['state'];

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const { manifest, decodedState, isUserOnboarded, interfaces } = params;

    const portalCustomOnboardingPath =
      manifest?.services?.userOnboarding?.path ||
      manifest?.services?.onboarding?.userOnboardingPath;

    const doesPortalImplementsItsOwnUserOnboarding =
      !!portalCustomOnboardingPath;

    const isInvitationFlow = decodedState?.invite === 'true';
    const doesUserRequiresOnboarding = !isUserOnboarded;

    const forceUserOnboardingCriterionKey =
      manifest?.services?.userOnboarding?.forceUserOnboardingCriterionKey;

    const isForcedUserOnboarding = forceUserOnboardingCriterionKey
      ? await interfaces?.v1?.criterion?.checkByCriterionKey?.(
          forceUserOnboardingCriterionKey
        )
      : false;

    const isDisabledUserOnboarding =
      manifest?.services?.userOnboarding?.enable === false;

    const doesUserNeedsPortalOnboarding =
      !isDisabledUserOnboarding &&
      (isInvitationFlow ||
        doesUserRequiresOnboarding ||
        isForcedUserOnboarding) &&
      doesPortalImplementsItsOwnUserOnboarding;

    if (doesUserNeedsPortalOnboarding) {
      const url = new URL(portalCustomOnboardingPath, window.location.origin);
      const urlParams = new URLSearchParams(decodedState);

      urlParams.forEach((v, k) => {
        const invalidKey = this.EXCLUDED_KEYS_FROM_STATE_LIST.some(
          (keyName) => keyName === k
        );
        if (v && k && !invalidKey) {
          url.searchParams.append(k, v);
        }
      });

      const redirectUrl = url?.pathname + url?.search;

      await new Promise(() =>
        //@ts-expect-error Update the Commons types when available.
        interfaces?.v1?.navigation?.redirect(redirectUrl)
      );
    } else {
      return super.handle(params);
    }
  }
}
