import { systemImportWithLocale } from '../../../utils/systemImportWithLocale';
import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';

export default class LoggedinErrorHandler extends AbstractLoggedInHandler {
  private _errorQueryParam = 'error_type';

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const errorSearchParam = urlParams.get(this._errorQueryParam);

    if (errorSearchParam) {
      const { decodedState } = params;
      const skipAuthErrorScreen = !!decodedState?.skipAuthErrorScreen;
      if (skipAuthErrorScreen) {
        const { proceedToShell, manifest, interfaces, decodedState } = params;
        const additionalParams = new URLSearchParams({
          [this._errorQueryParam]: errorSearchParam
        });

        return proceedToShell(
          manifest,
          interfaces,
          decodedState,
          additionalParams
        );
      }
      const { login } = params?.manifest?.services || {};
      const loginAssetReference =
        login?.assetReference || '@jarvis/react-shell-login';
      return (await systemImportWithLocale(loginAssetReference)) as any;
    }

    return super.handle(params);
  }
}
