import { ManifestType } from '../../../types/manifest';

export function isEnableLoginResourceSatisfiedBy(
  manifest: ManifestType
): boolean {
  const { login } = manifest?.services || {};
  const loginAssetReference =
    login?.assetReference || '@jarvis/react-shell-login';
  const isLoginEnabled = login?.enable !== false;

  return isLoginEnabled && !!loginAssetReference;
}
