import { systemImportWithLocale } from '../../../utils/systemImportWithLocale';
import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';

export default class ShellUserOnboardingHandler extends AbstractLoggedInHandler {
  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const {
      isUserOnboarded,
      interfaces,
      manifest,
      proceedToShell,
      decodedState
    } = params;

    const doesUserOnboardingRequiresOnboardingScreen =
      await interfaces.v1.userInterface.onboarding.shouldRenderOnboardingScreen();

    if (!isUserOnboarded) {
      if (doesUserOnboardingRequiresOnboardingScreen) {
        const { login } = manifest?.services || {};
        const loginAssetReference =
          login?.assetReference || '@jarvis/react-shell-login';

        return (await systemImportWithLocale(loginAssetReference)) as any;
      }
      await interfaces.v1.userInterface.onboarding.onboardUser();
      return proceedToShell(manifest, interfaces, decodedState);
    }

    return super.handle(params);
  }
}
