import { InterfacesType } from '@jarvis/shell-commons/dist/interface';
import { displayNativeAuth } from '../../utils/jweb';
import { GenerateAuthenticationUrlParams } from '@jarvis/shell-commons/dist/services/session/loginService';
import { unsolvedPromise } from '../../utils/unsolvedPromise';

export default async function doNativeAuthAndRedirectToLoggedin(
  interfaces: InterfacesType
) {
  const { store, sessionInterface, localization, navigation } = interfaces.v1;
  const { postLoginRedirect } = store.state?.manifest?.services?.login || {};
  const additionalParams = new URLSearchParams();

  const { error } = (await displayNativeAuth()) || {};

  if (error) {
    additionalParams.append('error_type', error?.code);
  }

  const options: GenerateAuthenticationUrlParams = {
    postLoginRedirect: postLoginRedirect,
    authorizationUrl: `/loggedin?${additionalParams.toString()}`
  };

  if (localization?.enabled) {
    options.country = localization.country;
    options.language = localization.language;
  }

  const authenticationUrl = await sessionInterface.generateAuthenticationUrl(
    options
  );

  //@ts-expect-error Update the Commons types when available.
  navigation.redirect(authenticationUrl);

  await unsolvedPromise();
}
