import { InterfacesType } from '@jarvis/shell-commons/dist/interface';

type GenerateAuthenticationUrlParams = {
  country?: string;
  language?: string;
  authorizationUrl: string;
  postLoginRedirect: string;
  target?: string;
};

let isHandlingRedirect: boolean;

export async function redirectUserToIdentityProvider(
  interfaces: InterfacesType
): Promise<void> {
  try {
    if (isHandlingRedirect) return;

    isHandlingRedirect = true;
    const { store, sessionInterface, localization, navigation } = interfaces.v1;
    const { postLoginRedirect } = store.state?.manifest?.services?.login || {};

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') || '';

    const providers = await sessionInterface.getProviderList({ email });
    const options: GenerateAuthenticationUrlParams = {
      postLoginRedirect: postLoginRedirect,
      authorizationUrl: providers[0]?.authorizationEndpoint
    };

    if (localization?.enabled) {
      options.country = localization.country;
      options.language = localization.language;
    }

    if (email) {
      options.target = 'password';
    }

    const authenticationUrl = await sessionInterface.generateAuthenticationUrl(
      options
    );

    //@ts-expect-error Update the Commons types when available.
    navigation.redirect(authenticationUrl);

    /*
     * @TODO This return is not determined by the internal logic of this function, but
     *   by the place where the function has been called, which is incorrect. Find a way
     *   to fix this.
     */
    await new Promise(() => undefined);
  } catch (e) {
    console.error(e);
    isHandlingRedirect = false;
  }
}
