import { ManifestType } from '../../../types/manifest';

export function shouldDisplayLoginMFeSpecification(
  manifest: ManifestType
): boolean {
  const { login } = manifest?.services || {};
  const emailGathering = login?.emailGathering || false;

  const urlParams = new URLSearchParams(window.location.search);
  const emailAlreadySelected = !!urlParams.get('email');

  return emailGathering && !emailAlreadySelected;
}
