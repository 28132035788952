import { InterfacesType } from '@jarvis/shell-commons/dist/interface';
import { ManifestType } from '../../types/manifest';
import getDecodedURLStateParam, {
  DecodedStateParamType
} from '../../utils/getDecodedURLStateParam';
import DefaultHandler from './handlers/DefaultHandler';
import InvitationFlowHandler from './handlers/InvitationFlowHandler';
import LoggedinErrorHandler from './handlers/LoggedinErrorHandler';
import PortalCustomUserOnboardingHandler from './handlers/PortalCustomUserOnboardingHandler';
import ShellUserOnboardingHandler from './handlers/ShellUserOnboardingHandler';
import SkipUserOnboardingHandler from './handlers/SkipUserOnboardingHandler';
import type { LoggedInHandleParamsType } from './types';

export default async function resolveLoggedInPath(
  interfaces: InterfacesType,
  manifest: ManifestType
): Promise<void> {
  const decodedState = getDecodedURLStateParam();
  const { sessionInterface, userInterface } = interfaces.v1;
  const isUserOnboarded = sessionInterface.isLoggedIn()
    ? await userInterface.onboarding.isUserOnboarded()
    : false;

  const handlerParams: LoggedInHandleParamsType = {
    decodedState,
    isUserOnboarded,
    interfaces,
    manifest,
    proceedToShell
  };

  const loggedinErrorHandler = new LoggedinErrorHandler();
  const skipUserOnboardingHandler = new SkipUserOnboardingHandler();
  const portalCustomUserOnboardingHandler =
    new PortalCustomUserOnboardingHandler();
  const shellUserOnboardingHandler = new ShellUserOnboardingHandler();
  const invitationFlowHandler = new InvitationFlowHandler();
  const defaultHandler = new DefaultHandler();

  loggedinErrorHandler
    .setNext(skipUserOnboardingHandler)
    .setNext(portalCustomUserOnboardingHandler)
    .setNext(shellUserOnboardingHandler)
    .setNext(invitationFlowHandler)
    .setNext(defaultHandler);

  return loggedinErrorHandler.handle(handlerParams);
}

async function proceedToShell(
  manifest: ManifestType,
  interfaces: InterfacesType,
  state: DecodedStateParamType,
  additionalParams?: URLSearchParams
): Promise<void> {
  const postLoginRedirectKey = 'postLoginRedirect';
  const urlParams = new URLSearchParams(window.location.search);

  const searchParamPostLoginRedirectUrl = urlParams.get(postLoginRedirectKey);
  const StatePostLoginRedirectUrl = state?.postLoginRedirect;
  const defaultPostLoginRedirectUrl = manifest.portal.basePath;

  const postLoginRedirectPath =
    searchParamPostLoginRedirectUrl ||
    StatePostLoginRedirectUrl ||
    defaultPostLoginRedirectUrl;

  const redirectionUrl = new URL(
    `${window.location.origin}${postLoginRedirectPath}`
  );

  if (additionalParams) {
    additionalParams.forEach((value, key) => {
      redirectionUrl.searchParams.append(key, value);
    });
  }

  //@ts-expect-error Update the Commons types when available.
  interfaces.v1.navigation.redirect(redirectionUrl.toString());

  await new Promise(() => undefined);
}

export { LoggedInHandleParamsType, proceedToShell };
