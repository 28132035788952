import { InterfacesType } from '@jarvis/shell-commons/dist/interface';
import { registerApplication } from 'single-spa';
import resolveLoggedInPath from '../closures/resolveLoggedInPath';
import { ManifestType } from '../types/manifest';
import { loginUseCase } from '../usecases/login';
import { isEnableLoginResourceSatisfiedBy } from '../usecases/specifications/login';

export async function initializeLoginMFe(
  interfaces: InterfacesType,
  manifest: ManifestType,
  customProps
): Promise<void> {
  if (!isEnableLoginResourceSatisfiedBy(manifest)) return;

  const { login } = manifest?.services || {};
  const loginAssetReference =
    login?.assetReference || '@jarvis/react-shell-login';
  const { navigation } = interfaces?.v1 || {};

  registerApplication({
    name: loginAssetReference,
    app: async () => {
      const isInLoginPath = navigation.location.pathname === '/login';
      const isInLoggedinPath = navigation.location.pathname === '/loggedin';
      const isInLoggedoutPath = navigation.location.pathname === '/loggedout';

      if (isInLoginPath || isInLoggedoutPath) {
        return loginUseCase(interfaces, manifest);
      } else if (isInLoggedinPath) {
        return resolveLoggedInPath(interfaces, manifest);
      }
    },
    activeWhen: () =>
      isEnableLoginResourceSatisfiedBy(manifest) && isInLoginRelatedPath(),
    customProps: {
      ...customProps,
      properties: (login as any)?.properties
    }
  });

  function isInLoginRelatedPath() {
    const { navigation } = interfaces?.v1 || {};
    const loginRelatedPaths = ['/login', '/loggedin', '/loggedout'];

    // @TODO Check the possibility to take this behavior to the navigation service.
    const _isLoginRelatedPath = loginRelatedPaths.some(
      (path) =>
        window.location.pathname === navigation?.createHref({ pathname: path })
    );

    return _isLoginRelatedPath;
  }
}
